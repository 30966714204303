import { useState } from "react";
import { IMAGES } from "../../../utils/Images";
import * as math from "mathjs";
import styles from "./calculator.module.css";

export default function CalculatorModal({
  showCalculatorModal,
  setShowCalculatorModal,
}) {
  const [result, setResult] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");

  const handleClick = (e) => {
    const buttonName = e.target.name;

    if (isNaN(buttonName)) {
      // If an operator button is clicked
      if (selectedOperator && selectedOperator !== buttonName) {
        // If a different operator is already selected, update the selected operator
        setSelectedOperator(buttonName);
        setResult(result.slice(0, -1).concat(buttonName));
      } else if (!selectedOperator) {
        // Otherwise, select the operator and append it to the result
        setSelectedOperator(buttonName);
        setResult(result.concat(buttonName));
      }
    } else {
      // If a number button is clicked
      if (selectedOperator) {
        // If an operator is already selected, append the number to the result after the operator
        if (result.slice(-1) === selectedOperator) {
          setResult(result.concat(buttonName));
        } else {
          setResult(result + selectedOperator + buttonName);
        }
        setSelectedOperator("");
      } else {
        // Otherwise, simply append the number to the result
        setResult(result.concat(buttonName));
      }
    }
  };

  const clear = () => {
    setResult("");
    setSelectedOperator("");
  };

  const backspace = () => {
    setResult(result.slice(0, -1));
    setSelectedOperator("");
  };

  const calculate = () => {
    try {
      setResult(math.evaluate(result).toString());
      setSelectedOperator("");
    } catch (err) {
      setResult("Error");
    }
  };

  return (
    <>
      {showCalculatorModal && (
        <>
          <div className="fixed inset-0  w-full z-50 h-full flex items-center justify-center scale-100 md:scale-140">
            <div className="bg-white shadow-md border-[1px] border-[#8C8C8C]    rounded-md ">
              <div
                onClick={() => setShowCalculatorModal(false)}
                className={`bg-[#4D4D4D] text-white flex rounded-t-[4px] justify-end p-3 items-center cursor-pointer`}
              >
                <img src={IMAGES.IMAGE_CLOSE} className="w-[25px] h-[25px]" />
              </div>

              <div className="">
                <div className="containercalculator">
                  <form>
                    <input
                      className={styles.inputCalculator}
                      type="text"
                      value={result}
                      placeholder="0"
                    />
                  </form>
                  <div id={styles.calculatorform} className={styles.keypad}>
                    <button
                      className={styles.highlight}
                      onClick={clear}
                      id={styles.clearcalculator}
                    >
                      Clear
                    </button>
                    <button
                      className={styles.highlight}
                      onClick={backspace}
                      id={styles.backspacecolor}
                    >
                      C
                    </button>
                    <button
                      className={styles.highlight}
                      name="/"
                      onClick={handleClick}
                    >
                      &divide;
                    </button>
                    <button
                      id={styles.buttoncalculator}
                      name="7"
                      onClick={handleClick}
                    >
                      7
                    </button>
                    <button name="8" onClick={handleClick}>
                      8
                    </button>
                    <button name="9" onClick={handleClick}>
                      9
                    </button>
                    <button
                      className={styles.highlight}
                      name="*"
                      onClick={handleClick}
                    >
                      &times;
                    </button>
                    <button name="4" onClick={handleClick}>
                      4
                    </button>
                    <button name="5" onClick={handleClick}>
                      5
                    </button>
                    <button name="6" onClick={handleClick}>
                      6
                    </button>
                    <button
                      className={styles.highlight}
                      name="-"
                      onClick={handleClick}
                    >
                      &ndash;
                    </button>
                    <button name="1" onClick={handleClick}>
                      1
                    </button>
                    <button name="2" onClick={handleClick}>
                      2
                    </button>
                    <button name="3" onClick={handleClick}>
                      3
                    </button>
                    <button
                      className={styles.highlight}
                      name="+"
                      onClick={handleClick}
                    >
                      +
                    </button>
                    <button name="0" onClick={handleClick}>
                      0
                    </button>
                    <button
                      className={styles.highlight}
                      name="."
                      onClick={handleClick}
                    >
                      .
                    </button>
                    <button
                      className={styles.highlight}
                      name="="
                      onClick={calculate}
                      id={styles.resultcalculator}
                    >
                      =
                    </button>
                  </div>
                </div>
                {/* <img
                  src={IMAGES.CALCULATOR_MODAL}
                  className="  object-cover "
                  draggable={false}
                /> */}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#000000]"></div>
        </>
      )}
    </>
  );
}
