import React from "react";

const AvatarComponent = ({
  src,
  alt = "Avatar",
  className = "",
  size = 30,
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full overflow-hidden bg-gray-200 ${className}`}
      style={{ width: size, height: size }}
    >
      {src ? (
        <img
          src={src}
          alt={alt}
          className="object-cover w-full h-full"
        />
      ) : (
        <span className="text-gray-500 text-sm">{alt.charAt(0).toUpperCase()}</span>
      )}
    </div>
  );
};

export default AvatarComponent;
