"use client";

import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import CardHeader from "../../Components/CardHeader";
import AvatarComponent from "../../Components/Avatar";
import TextFields from "../../Components/TextField";
import CustomButton from "../../Components/CustomButton";
import Container from "../../Components/Container";

const Profile = () => {
  const navigate = useNavigate();
  const handleClicked = () => navigate("/editprofile");
  const changePasswordScreen = () => navigate("/changepassword");

  return (
    <Container>
      <div className="w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]">
        {/* Header */}
        <CardHeader header="Profile" onClick={() => navigate("/")} />

        <div className="mt-6 max-w-full mx-auto p-8 bg-[#FFFFFF] shadow-lg">
          {/* Profile Section */}
          <div className="flex flex-col md:flex-row items-center md:items-start">
            {/* Avatar on the left */}
            <div className="relative flex-shrink-0 mb-6 md:mb-0 md:mr-8">
              <AvatarComponent
                src="/assets/Avatar.png"
                alt="Simon Lewis"
                size={120}
              />
              <div className="absolute bottom-0 right-0 bg-[#0B54B4] rounded-full p-1">
                <FaArrowUp size={18} color="#FFFFFF" />
              </div>
            </div>

            {/* Profile Info */}
            <div className="flex-1 space-y-4 w-full md:w-auto text-center md:text-left">
              <TextFields
                type="text"
                placeholder="Simon"
                icon={"/assets/Group 81765.svg"}
                disabled={true}
              />
              <TextFields
                type="text"
                placeholder="Lewis"
                icon={"/assets/Group 81765.svg"}
                disabled={true}
              />
              <TextFields
                type="email"
                placeholder="simon.lewis@gmail.com"
                icon={"/assets/Group 333.svg"}
                disabled={true}
              />
              <TextFields
                type="text"
                placeholder="FME_DEMO"
                icon={"/assets/Group 333.svg"}
                disabled={true}
              />
              <TextFields
                type="password"
                placeholder="************"
                icon={"/assets/Forma 1.svg"}
                disabled={true}
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-6 mt-10 w-full">
            <CustomButton
              text="Edit Profile"
              onClick={handleClicked}
              width="180px"
              height="60px"
              borderRadius="8px"
              className="bg-[#060531] text-white sm:w-[180px]"
            />
            <CustomButton
              text="Change Password"
              onClick={changePasswordScreen}
              width="250px"
              height="60px"
              borderRadius="8px"
              className="bg-white border border-gray-300 hover:bg-gray-100 sm:w-[250px]"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
