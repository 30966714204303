import React from "react";

import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import TextFields from "../../Components/TextField";
import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import CustomButton from "../../Components/CustomButton";

import { contactUsSchema } from "../../utils/schema";


const ContactUs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true);
      console.log("values -->", JSON.stringify(values));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setSubmitting(false);
      setIsLoading(false);
      navigate("/"); // Navigate after successful submission
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  return (
    <Container>
      <form
        className="w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]"
        onSubmit={handleSubmit}
      >
        <CardHeader header="Contact Us" onClick={() => navigate(-1)} />

        <div className="space-y-2 p-6 bg-[#FFFFFF] shadow-md rounded-b-xl">
          <div>
            <label
              htmlFor="email-field"
              className="block text-lg font-medium mb-2"
              style={{ fontFamily: "var(--font-poppins)" }}
            >
              Email<span className="text-[#D9001B]">*</span>
            </label>
            <TextFields
              placeholder="Enter Email"
              width="98%"
              value={values.email}
              onChange={handleChange}
              name="email"
            />
            {errors.email && touched.email && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errors.email}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="subject-field"
              className="block text-lg font-medium mb-2"
              style={{ fontFamily: "var(--font-poppins)" }}
            >
              Subject<span className="text-[#D9001B]">*</span>
            </label>
            <TextFields
              placeholder="Enter Subject"
              width="98%"
              value={values.subject}
              onChange={handleChange}
              name="subject"
            />
            {errors.subject && touched.subject && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errors.subject}
              </span>
            )}
          </div>

          <div>
            <label
              htmlFor="message-field"
              className="block text-lg font-medium mb-2"
              style={{ fontFamily: "var(--font-poppins)" }}
            >
              Message<span className="text-[#D9001B]">*</span>
            </label>
            <TextFields
              placeholder="Enter Message..."
              type="text"
              multiline={true}
              rows={6}
              width="98%"
              value={values.message}
              onChange={handleChange}
              name="message"
            />
            {errors.message && touched.message && (
              <span style={{ color: "red", fontSize: "14px" }}>
                {errors.message}
              </span>
            )}
          </div>

          <div className="flex justify-center">
            <CustomButton
              text="Send"
              type="submit" 
              width="170px"
              height="55px"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};

export default ContactUs;
