import React from "react";
import { IMAGES } from "../../../utils/Images";

const ComponentHeader = ({ setZoom, zoom }) => {
  const ButtonData = [
    // {
    //   onClick: () => {},
    //   image: IMAGES.HIDE,
    // },
    // {
    //   onClick: () => {},
    //   image: IMAGES.SURFACE,
    // },
    {
      onClick: () => {

        console.log(zoom);
        
        if (zoom <= 4 && zoom > 0) setZoom(zoom - 1);
      },
      image: IMAGES.PLUS,
    },
    {
      onClick: () => {
        console.log(zoom);
        if (zoom > -1 && zoom < 4) setZoom(zoom + 1);
      },
      image: IMAGES.MINUS,
    },
  ];
  const ButtonDataTwo = [
    {
      onClick: () => {},
      image: IMAGES.LEFT_ARROW,
    },
    {
      onClick: () => {},
      image: IMAGES.LEFT_ARROW_NAVIGATE,
    },
    {
      onClick: () => {},
      image: IMAGES.RIGHT_ARROW,
    },
    {
      onClick: () => {},
      image: IMAGES.RIGHT_ARROW_NAVIGATE,
    },
  ];

  return (
    <div className="bg-[#4D4D4D] text-white flex rounded-t-[4px] justify-between md:px-2 md:py-3 px-[3px] py-2 items-center">
      <div className="flex md:gap-[6px] gap-[2px] md:px-2 px-1">
        {ButtonData.map((item, ind) => {
          return (
            <div
              key={ind}
              onClick={item.onClick}
              className="flex bg-white md:p-2 p-[3px]   cursor-pointer rounded-sm items-center"
            >
              <img
                src={item.image}
                className="md:w-[17px] md:h-[17px] w-[7px] h-[7px]  object-contain"
              />
            </div>
          );
        })}

        {/* <div className="flex    md:gap-2 gap-1  bg-white md:p-1  p-[3px]  rounded-sm items-center">
          <p className="text-[#060531] md:text-[12px] text-[8px] xsm:text-[5px] font-normal">
            Automatic Zoom
          </p>
          <img
            src={IMAGES.DOWN_ARROW}
            className="md:w-[7px] md:h-[7px] w-[4px] h-[4px] object-contain"
          />
        </div> */}
      </div>

      <div className="flex md:gap-2 gap-[3px]  px-2 items-center">
        {/* {ButtonDataTwo.map((item, ind) => {
          return (
            <div
              onClick={item.onClick}
              className="flex bg-[#656565] md:p-2 p-[3px] cursor-pointer rounded-sm items-center"
            >
              <img
                src={item.image}
                className="md:w-[17px] md:h-[17px] w-[8px] h-[8px] object-contain"
              />
            </div>
          );
        })} */}

        <p className="text-white font-medium md:text-[16px] text-[10px]">
          Page:
        </p>

        <div className="flex  rounded-sm bg-white md:py-1 py-[2px] xl:px-6 lg:px-4 px-2 justify-center items-center">
          <p className="text-[#060531] md:text-[16px] text-[8px] font-medium">
            1
          </p>
        </div>

        <p className="text-white font-medium md:text-[16px]  text-[8px]">
          of 1
        </p>

        {/* <div className="flex   rounded-sm items-center  bg-white md:p-2 p-[3px]">
          <img
            src={IMAGES.BOOK}
            className="md:w-[17px] md:h-[17px] w-[8px] h-[8px] object-contain"
          />
        </div> */}
      </div>
    </div>
  );
};

export default ComponentHeader;
