import React from "react";

import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import SettingCard from "../../Components/SettingCard";
import DeleteModal from "../../Components/DeleteModal";
import CustomButton from "../../Components/CustomButton";
import CustomModal from "../../Components/CustomModal";
import PlanCard from "../../Components/CustomCard";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe("your-publishable-key-here");

function Settings() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [isOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [checkoutOpen, setCheckoutOpen] = React.useState(false);

  const featuresList = Array(5).fill("Lorem ipsum");

  // Delete Modal
  const handleIsOpen = () => setIsOpen(true);
  const handleIsClose = () => setIsOpen(false);
  // SubscritipnModal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Checkout
  const handleCheckoutOpen = () => setCheckoutOpen(true);
  const handleCheckoutClose = () => setCheckoutOpen(false);

  const handleDeleteAccount = () => {
    navigate("/login");
  };
  const handleLogout = () => navigate("/login");

  const handleClicked = () => navigate("/");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      console.log("Payment Method:", paymentMethod);
    }
  };

  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Settings" onClick={handleClicked} />

        <SettingCard
          header="Basic Settings"
          items={[
            {
              title: "Terms and Conditions",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/termsconditions",
            },
            {
              title: "Privacy Policy",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/privacypolicy",
            },
            {
              title: "About Us",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/aboutus",
            },
            {
              title: "Contact Us",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              path: "/contactus",
            },
            {
              title: "Subscription",
              icon: <AiOutlineArrowRight size={20} color="#4D4D4D" />,
              onButtonClick: handleOpen,
            },
          ]}
          // buttonSignoutLabel="SignOut"
          buttonLabel="Delete Account"
          onButtonClick={handleIsOpen}
          onSignOutButtonClick={handleLogout}
        />
      </div>

      {/* Delete Modal */}
      <DeleteModal
        open={isOpen}
        handleClose={handleIsClose}
        title="Are You Sure"
        description="You want to delete your account"
        imageSrc={"/assets/delete.svg"}
        isTrue={true}
        width={600}
        height={600}
      >
        <>
          <CustomButton
            text="Yes"
            onClick={handleDeleteAccount}
            width="150px"
            height="50px"
            borderRadius="8px"
            className="bg-[#060531]"
          />

          <CustomButton
            text="No"
            onClick={handleIsClose}
            width="150px"
            height="50px"
            borderRadius="8px"
            className="bg-white border border-gray-300 hover:bg-gray-100"
          />
        </>
      </DeleteModal>

      <CustomModal
        open={open}
        onClose={handleClose}
        title="Premium plan for users"
        closeIcon={true}
      >
        <div className="flex flex-col md:flex-row gap-6 p-6">
          {/* Monthly Plan */}
          <PlanCard
            title="Monthly"
            price="$4.99"
            features={featuresList}
            buttonText="Get Plan"
            titleColor="text-green-600"
            circleColor="bg-gradient-to-br from-green-400 to-green-600"
            buttonColor="border border-gray-900 text-gray-900"
            onButtonClick={handleCheckoutOpen}
          />

          {/* Yearly Plan */}
          <PlanCard
            title="Yearly"
            price="$19.99"
            features={featuresList}
            buttonText="Get Plan"
            titleColor="text-purple-600"
            circleColor="bg-gradient-to-br from-purple-400 to-purple-600"
            buttonColor="bg-gray-900 text-white"
            onButtonClick={handleCheckoutOpen}
          />
        </div>
      </CustomModal>

      <CustomModal
        open={checkoutOpen}
        onClose={handleCheckoutClose}
        title=""
        closeIcon={false}
      >
        <form
          onSubmit={handleSubmit}
          className="max-w-md mx-auto p-2 space-y-6"
        >
          <div className="border border-gray-300 p-6 rounded-md">
            <p className="text-[#000000]">Your subscription</p>
            <div className="flex justify-between mt-2">
              <span className="font-semibold text-[#858585]">Yearly Plan</span>
              <span className="text-[#858585]">$19.00 Billed Yearly</span>
            </div>
            {/* Divider */}
            <hr className="my-4" />
            <div className="flex justify-between font-semibold">
              <span className="text-[#000000]">Total</span>
              <span className="text-[#000000]">$15.00</span>
            </div>
          </div>

          <div className="flex space-x-2">
            <button
              type="button"
              className="flex-1 p-3 border-2 border-teal-600 bg-white rounded-lg flex flex-col items-start justify-center space-y-1"
            >
              <span className="material-icons">
                <img
                  src="/assets/credit-card.svg"
                  alt="Credit Card Icon"
                  className="h-6 w-6"
                />
              </span>
              <span>Card</span>
            </button>
          </div>

          {/* Card Information */}
          <div className="p-3 border border-gray-300 rounded-md flex justify-between items-center w-full">
            <div className="flex items-center w-full">
              <CardNumberElement
                className="w-full"
                options={{
                  placeholder: "Card Number",
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#000",
                      letterSpacing: "0.025em",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <div className="flex space-x-2 ml-2">
                <img
                  src="/assets/card.png"
                  alt="Visa"
                  className="h-10 w-18 object-contain"
                />
              </div>
            </div>
          </div>

          <div className="flex space-x-2">
            <div className="w-1/2">
              <div className="p-5 border border-gray-300 rounded-md">
                <CardExpiryElement
                  className="w-full"
                  options={{
                    placeholder: "Expiration Date",
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#000",
                        letterSpacing: "0.025em",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div className="w-1/2">
              <div className="p-5 border border-gray-300 rounded-md">
                <CardCvcElement
                  className="w-full"
                  options={{
                    placeholder: "Security Code",
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#000",
                        letterSpacing: "0.025em",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          {/* Country and Postal Code */}
          <div className="flex space-x-2">
            <div className="w-1/2">
              <div className="relative">
                <select className="w-full p-5 border border-gray-300 rounded-md appearance-none pr-10 bg-white">
                  <option>United States</option>
                  <option>UAE</option>
                  <option>Pakistan</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg
                    className="h-4 w-4 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="w-1/2">
              <input
                type="text"
                placeholder="Postal Code"
                className="w-full p-5 border border-gray-300 rounded-md"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!stripe}
            className="w-full bg-[#000B22] text-white text-[20px] p-4 outline-none rounded-md"
          >
            Subscribe
          </button>
        </form>
      </CustomModal>
    </Container>
  );
}

export default Settings;
