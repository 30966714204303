// Timer.js
import React, { useEffect } from "react";

const Timer = React.memo(
  ({ timeRemaining, onTimeUp, setTimeRemaining }: any) => {
    useEffect(() => {
      if (timeRemaining <= 0) {
        onTimeUp();
        return;
      }

      const interval = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(interval);
            onTimeUp();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }, [timeRemaining, onTimeUp, setTimeRemaining]);

    const formatTime = (seconds) => {
      const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(
        2,
        "0"
      );
      const secs = String(seconds % 60).padStart(2, "0");
      return `${hours}:${minutes}:${secs}`;
    };

    return <p>{formatTime(timeRemaining)}</p>;
  }
);

export default Timer;
