"use client";

import React from "react";


import { HiOutlineArrowLeft } from "react-icons/hi";


function CardHeader({ header, onClick }) {
  return (
    <div className="flex justify-between items-center bg-[#060531] border-1 border-[#060531] p-4 px-8">
      <h1 className="text-xl font-[400] text-white">{header}</h1>
      <div
        className="w-8 h-8 rounded-full bg-white flex justify-center items-center"
        // onClick={() => router.back()}
        onClick={onClick}
      >
        <HiOutlineArrowLeft size={18} color="#000000" style={{ cursor:"pointer" }} />
      </div>
    </div>
  );
}

export default CardHeader;
