// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter,
//   Routes,
//   Route,
//   Navigate,
//   useLocation,
// } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import Index from "./Screens/Auth";
// import Home from "./Screens/Home";
// import Performance from "./Screens/Performance";
// import Previous from "./Screens/Previous";
// import Test from "./Screens/Modals/Test";
// import AuthWrapper from "./Screens/Auth/AuthWrapper/Index";
// import { PrimeReactProvider } from "primereact/api";
// import "primereact/resources/themes/saga-blue/theme.css";
// import "primereact/resources/primereact.min.css";
// import Splash from "./Screens/Splash";
// import { GetUser, LoginUser } from "./redux/slices/AuthSlice";

// function App() {
//   const [isLoading, setIsLoading] = useState(true);
//   const { token } = useSelector((state) => state.Auth);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       dispatch(GetUser());
//       setIsLoading(false);
//     }, 2000); // Adjust the timeout as needed

//     return () => clearTimeout(timer);
//   }, [dispatch]);

//   // useEffect(() => {
//   //   // Simulate loading/auth check
//   //   const timer = setTimeout(() => {
//   //     const storedToken = localStorage.getItem("token");
//   //     if (storedToken) {
//   //       dispatch(LoginUser({})); // Restore the token to Redux
//   //     }
//   //     setIsLoading(false);
//   //   }, 2000); // Adjust the timeout as needed

//   //   return () => clearTimeout(timer);
//   // }, [dispatch]);

//   if (isLoading) {
//     return <Splash />;
//   }

//   return (
//     <div className="app">
//       <PrimeReactProvider>
//         <Routes>
//           <Route
//             path="/login"
//             element={!token ? <Index /> : <Navigate to={"/"} />}
//           />
//           <Route
//             path="/forgotPassword"
//             element={!token ? <AuthWrapper /> : <Navigate to={"/"} />}
//           />
//           <Route
//             path="/"
//             element={token ? <Home /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/previous"
//             element={token ? <Previous /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/performance"
//             element={token ? <Performance /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/test"
//             element={token ? <Test /> : <Navigate to="/login" />}
//           />
//         </Routes>
//       </PrimeReactProvider>
//     </div>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

import Index from "./Screens/Auth";
import Home from "./Screens/Home";
import Performance from "./Screens/Performance";
import Previous from "./Screens/Previous";
import Test from "./Screens/Modals/Test";
import Settings from "./Screens/Settings/Settings";
import AuthWrapper from "./Screens/Auth/AuthWrapper/Index";
import AboutUs from "./Screens/AboutUs/AboutUs";
import ContactUs from "./Screens/ContactUs/ContactUs";
import PrivacyPolicy from "./Screens/PrivacyPolicy/PrivacyPolicy";
import TermsConditon from "./Screens/TermsCondition/TermsConditon";
import Profile from "./Screens/Profile/Profile";
import EditProfile from "./Screens/Profile/EditProfile";
import ChangePassword from "./Screens/ChangePassword/ChangePassword";
import Splash from "./Screens/Splash";

import { GetUser } from "./redux/slices/AuthSlice";
import Checkout from "./Screens/CheckoutForm/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("your-publishable-key-here");

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(GetUser());
      setIsLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, [dispatch]);

  if (isLoading) {
    return <Splash />;
  }

  return (
    <div className="app">
      <PrimeReactProvider>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/login"
                element={!token ? <Index /> : <Navigate to={"/"} />}
              />
              <Route
                path="/forgotPassword"
                element={!token ? <AuthWrapper /> : <Navigate to={"/"} />}
              />
              <Route
                path="/"
                element={token ? <Home /> : <Navigate to="/login" />}
              />
              <Route
                path="/previous"
                element={token ? <Previous /> : <Navigate to="/login" />}
              />
              <Route
                path="/performance"
                element={token ? <Performance /> : <Navigate to="/login" />}
              />
              <Route
                path="/settings"
                element={token ? <Settings /> : <Navigate to="/login" />}
              />
              <Route
                path="/aboutus"
                element={token ? <AboutUs /> : <Navigate to="/login" />}
              />
              <Route
                path="/contactus"
                element={token ? <ContactUs /> : <Navigate to="/login" />}
              />
              <Route
                path="/privacypolicy"
                element={token ? <PrivacyPolicy /> : <Navigate to="/login" />}
              />
              <Route
                path="/termsconditions"
                element={token ? <TermsConditon /> : <Navigate to="/login" />}
              />
              <Route
                path="/profile"
                element={token ? <Profile /> : <Navigate to="/login" />}
              />
              <Route
                path="/editprofile"
                element={token ? <EditProfile /> : <Navigate to="/login" />}
              />
              <Route
                path="/changepassword"
                element={token ? <ChangePassword /> : <Navigate to="/login" />}
              />
              <Route
                path="/test"
                element={token ? <Test /> : <Navigate to="/login" />}
              />
              <Route
                path="/checkout"
                element={token ? <Checkout /> : <Navigate to="/login" />}
              />
            </Routes>
          </BrowserRouter>
        </Elements>
        <Toaster />
      </PrimeReactProvider>
    </div>
  );
}

export default App;
