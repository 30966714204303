import React, { useState } from "react";
import SideNav from "./SideNav";
import { IMAGES } from "../utils/Images";
import { useNavigate } from "react-router-dom";
import { CustomIcon } from "./Icons";
import { useDispatch } from "react-redux";
import { LogoutUser } from "../redux/slices/AuthSlice";

const Container = ({ mainStyle, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggleBar, setToggleBar] = useState(false);

  const changeToggle = () => {
    setToggleBar(!toggleBar);
  };

  const handleLogout = () => {
    dispatch(LogoutUser());
    navigate("/login");
  };

  const goToProfileScreen = () => navigate('/profile');

  return (
    // <div className="grid grid-cols-12 w-full">
    <div className="flex flex-nowrap w-full h-full">
      {/* <div className={`transition-all duration-500`}> */}
      <SideNav toggleBar={toggleBar} toggle={changeToggle} />
      {/* </div> */}
      <div
        className={`${
          toggleBar ? "w-full lg:w-[94%]" : "w-full lg:w-[80%]"
        } transition-all duration-500 h-full`}
      >
        <header className="bg-[#4D4D4D] p-2 sm:p-5 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div
              onClick={changeToggle}
              className="bg-[#FFFFFF] p-2 shadow-sm rounded-[10px] md:px-4 cursor-pointer"
            >
              <CustomIcon
                name={"ic:round-menu"}
                fontSize={33}
                className="!text-[#707070]"
              />
            </div>
            <p className="text-white text-xl md:text-[30px] font-light">
              Create Tests
            </p>
          </div>
          <div className="flex items-center gap-8">
            <div
              onClick={goToProfileScreen}
              className="flex items-center justify-center gap-2 cursor-pointer"
            >
              <CustomIcon name={"fa-solid:home"} fontSize={20} />
              <p className="text-white text-[16px] sm:text-[18px] font-light  md:block hidden">
                My Account
              </p>
            </div>
            <di
              onClick={handleLogout}
              className="flex items-center gap-2 cursor-pointer"
            >
              <CustomIcon name={"heroicons-outline:logout"} fontSize={24} />
              <p className="text-white text-[18px] font-light  md:block hidden">
                Log out
              </p>
            </di>
          </div>
        </header>

        <div className={`${mainStyle}`}>{children}</div>
      </div>
    </div>
  );
};

export default Container;
