import React from "react";

import { FaArrowUp } from "react-icons/fa";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import AvatarComponent from "../../Components/Avatar";
import TextFields from "../../Components/TextField";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router-dom";

function EditProfile() {
  const navigate = useNavigate();

  const handleClicked = () => navigate("/");

  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Profile" onClick={() => navigate(-1)} />

        <div className="space-y-4 mt-2 p-4 flex flex-col justify-center items-center bg-[#FFFFFF] shadow-lg">
          <div className="relative">
            <AvatarComponent src="/assets/Avatar.png" alt="Simon Lewis" size={150} />
            <div
              className={`absolute bottom-0 right-4 transform translate-x-1/6 translate-y-1/6 bg-[#0B54B4] rounded-full p-1`}
            >
              <FaArrowUp size={18} color="#FFFFFF" />
            </div>
          </div>

          <div className="space-y-3 w-full max-w-[600px]">
            <TextFields
              type="text"
              placeholder="Simon"
              icon={"/assets/Group 81765.svg"}
            />
            <TextFields
              type="text"
              placeholder="Lewis"
              icon={"/assets/Group 81765.svg"}
            />
            <TextFields
              type="email"
              placeholder="simon.lewis@gmail.com"
              icon={"/assets/Group 333.svg"}
            />
            <TextFields
              type="text"
              placeholder="FME_DEMO"
              icon={"/assets/Group 333.svg"}
            />
          </div>

          <div className="flex justify-center" style={{ marginTop: "2rem", marginBottom:"2rem" }}>
            <CustomButton
              text="Update"
              onClick={handleClicked}
              width="150px"
              height="50px"
              borderRadius="8px"
              className="bg-[#060531]"
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default EditProfile;
