import { useState } from "react";
import Button from "../../../Components/Button";
import { IMAGES } from "../../../utils/Images";
import ConfirmFinishModal from "./ConfirmFinishModal";

export default function FinishModal({
  showFinishModal,
  setShowFinishModal,
  id,
}) {
  const [showConfirmFinishModal, setShowConfirmFinishModal] = useState(false);
  const handleSubmit = async () => {
    setShowFinishModal(false);
    setShowConfirmFinishModal(true);
  };
  return (
    <>
      {showFinishModal && (
        <>
          <div className="fixed inset-0  w-full z-50 h-full   flex items-center justify-center">
            <div className="bg-white shadow-md  border-[1px] border-[#8C8C8C] max-w-[800px]  w-[90%]   rounded-md ">
              <div
                className={`bg-[#4D4D4D] text-white flex rounded-t-[4px] md:p-3 p-2 items-center cursor-pointer`}
              >
                <p className="text-white font-normal md:text-[14px] text-[10px]">
                  Confirm Finish Section
                </p>
              </div>

              <div className="md:w-[70%] w-[90%] mx-auto grid place-items-center text-center p-4 md:gap-5 gap-3">
                <img
                  src={IMAGES.STOP_WATCH}
                  className="md:w-[128px] md:h-[148px] w-[90px] h-[100px] object-contain"
                />
                <p className="text-[#272727] lg:text-[24px] md:text-[18px] text-[14px]">
                  You stil have time remaining in this <strong>section.</strong>
                </p>
                <p className="text-[#272727] lg:text-[24px] md:text-[18px] text-[14px]">
                  If you choose to <strong>finish this section,</strong> you
                  will not be able to return to the section.
                </p>
                <p className="text-[#272727] lg:text-[24px] md:text-[18px] text-[14px]">
                  Are you sure you would like to
                  <strong> finish this section?</strong>
                </p>
                <div className="grid md:gap-7 gap-4 w-full md:my-0 my-4">
                  <Button
                    onPress={() => {
                      handleSubmit();
                    }}
                    divstyle={`w-full rounded-lg text-white bg-[#060531] font-regular md:py-2 py-2  lg:text-[24px] md:text-[18px] sm:text-[14px] text-[11px]`}
                    btnname="Yes I would like to finish this section"
                  />
                  <Button
                    onPress={() => setShowFinishModal(false)}
                    divstyle={`w-full rounded-lg text-white bg-[#060531] font-regular md:py-2 py-2 lg:text-[24px] md:text-[18px] sm:text-[14px] text-[11px]`}
                    btnname="No I would like to return to this section"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#000000]"></div>
        </>
      )}
      <ConfirmFinishModal
        id={id}
        setShowFinishModal={setShowFinishModal}
        showConfirmFinishModal={showConfirmFinishModal}
        setShowConfirmFinishModal={setShowConfirmFinishModal}
      />
    </>
  );
}
