import { IMAGES } from "../../../utils/Images";

export default function ImageModal({ showImageModal, setShowImageModal, image }) {
  return (
    <>
      {showImageModal && (
        <>
          <div className="fixed inset-0  w-full z-50 h-full flex items-center justify-center">
            <div className="bg-white shadow-md  border-[1px] border-[#8C8C8C] max-w-[800px] w-full rounded-md ">
              <div
                onClick={() => setShowImageModal(false)}
                className={`bg-[#4D4D4D] text-white flex rounded-t-[4px] justify-end p-3 items-center cursor-pointer`}
              >
                <img src={IMAGES.IMAGE_CLOSE} className="w-[25px] h-[25px]" />
              </div>

              <div className="w-full">
                <img
                  src={image}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#000000]"></div>
        </>
      )}
    </>
  );
}
