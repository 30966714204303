import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// Load your Stripe public key
const stripePromise = loadStripe("your-publishable-key-here");

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      console.log("Payment Method:", paymentMethod);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 space-y-6">
      <div className="border border-gray-300 p-4 rounded-md">
        <p className="text-[#000000]">Your subscription</p>
        <div className="flex justify-between mt-2">
          <span className="font-semibold text-[#858585]">Yearly Plan</span>
          <span className="text-[#858585]">$19.00 Billed Yearly</span>
        </div>
        {/* Divider */}
        <hr className="my-4" />
        <div className="flex justify-between font-semibold">
          <span className="text-[#000000]">Total</span>
          <span className="text-[#000000]">$15.00</span>
        </div>
      </div>

      <div className="flex space-x-2">
        <button
          type="button"
          className="flex-1 p-3 border-2 border-teal-600 bg-white rounded-lg flex flex-col items-start justify-center space-y-1"
        >
          <span className="material-icons">
            <img
              src="/assets/credit-card.svg"
              alt="Credit Card Icon"
              className="h-6 w-6"
            />
          </span>
          <span>Card</span>
        </button>
      </div>

      {/* Card Information */}
      <div className="p-3 border border-gray-300 rounded-md flex justify-between items-center w-full">
        <div className="flex items-center w-full">
          <CardNumberElement
            className="w-full"
            options={{
              placeholder: "Card Number",
              style: {
                base: {
                  fontSize: "16px",
                  color: "#000",
                  letterSpacing: "0.025em",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          <div className="flex space-x-2 ml-2">
            <img
              src="/assets/card.png"
              alt="Visa"
              className="h-10 w-18 object-contain"
            />
          </div>
        </div>
      </div>

      <div className="flex space-x-2">
        <div className="w-1/2">
          <div className="p-5 border border-gray-300 rounded-md">
            <CardExpiryElement
              className="w-full"
              options={{
                placeholder: "Expiration Date",
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#000",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="p-5 border border-gray-300 rounded-md">
            <CardCvcElement
              className="w-full"
              options={{
                placeholder: "Security Code",
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#000",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Country and Postal Code */}
      <div className="flex space-x-2">
        <div className="w-1/2">
          <div className="relative">
            <select className="w-full p-5 border border-gray-300 rounded-md appearance-none pr-10 bg-white">
              <option>United States</option>
              {/* Add more countries as needed */}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg
                className="h-4 w-4 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="w-1/2">
          <input
            type="text"
            placeholder="Postal Code"
            className="w-full p-5 border border-gray-300 rounded-md"
          />
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={!stripe}
        className="w-full bg-[#000B22] text-white text-[20px] p-4 outline-none rounded-md"
      >
        Subscribe
      </button>
    </form>
  );
};

const Checkout = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default Checkout;
