import React, { useState, useEffect } from "react";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import { CheckboxData } from "../../utils/DummyData";
import { useNavigate } from "react-router-dom";
import { get } from "../../api/axios.tsx";
import { useDispatch } from "react-redux";
import {
  setCasesForTest,
  setQuestionLimitForTest,
} from "../../redux/slices/AuthSlice.js";
import { Toast } from "../../Components/toast.jsx";
const Home = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(0); 
  const [cases, setCases] = useState(null);
  const [limitText, setLimitText] = useState(40);

  const updateItemsPerPage = () => {
    if (cases?.data?.total > 18) {
      if (window.innerWidth < 768) {
        setItemsPerPage(18); // Small screens
      } else if (window.innerWidth < 1200) {
        setItemsPerPage(24); // Medium screens
      } else {
        setItemsPerPage(48); // Large screens
      }
    } else {
      setItemsPerPage(cases?.data?.total);
    }
  };

  async function getAllItems() {
    try {
      const response = await get({
        url: "/case-objectives",
      });
      console.log(response);

      if (response?.data != null) {
        setCases(response);
      }
    } catch (error) {
      console.log(error?.message);
    }
  }
  useEffect(() => {
    updateItemsPerPage();
  }, [cases]);

  useEffect(() => {
    getAllItems();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);
  const totalPages = Math.ceil(cases?.data?.total / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = cases?.data?.data?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedCheckboxes([]);
  };
  const dispatch = useDispatch();
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedCheckboxes(currentItems?.map((item, index) => item?.id));
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCheckboxChange = (index) => {
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((item) => item !== index)
        : [...prevSelected, index]
    );
  };

  const handleSubmit = () => {
    if (selectedCheckboxes?.length) {
      dispatch(setCasesForTest(selectedCheckboxes));
      dispatch(setQuestionLimitForTest(limitText));
      navigate("/test", { state: { showModal: true } });
    } else {
      Toast({ message: "Please select case to proceed", isError: true });
    }
  };

  const getVisiblePageNumbers = () => {
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage === 1) {
        startPage = 1;
        endPage = 3;
      } else if (currentPage === totalPages) {
        startPage = totalPages - 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <Container mainStyle="h-full ">
      <div className="  mx-auto py-2 md:px-7 px-2 overflow-hidden h-full">
        <div className="flex items-center justify-between md:my-4 mb-2 ">
          <div className="flex items-center sm:-ml-2">
            <input
              type="checkbox"
              id="select-all"
              onChange={handleSelectAll}
              checked={
                selectedCheckboxes?.length === currentItems?.length &&
                currentItems?.length > 0
              }
            />
            <label
              htmlFor="select-all"
              className="ml-2 font-semibold md:text-[16px] text-[12px]"
            >
              Subjects
            </label>
          </div>
          <div>
            <Button
              onPress={handleSubmit}
              divstyle="rounded-full text-white bg-[#060531] font-medium lg:px-5 md:px-5 px-3 py-[6px] md:text-[12px] text-[8px]"
              btnname="Start Test"
              type="submit"
            />
          </div>
        </div>
        <div className="p-3 border-[1px] border-[#00000029] rounded-lg ">
          <div className="grid grid-flow-col lg:grid-rows-12 lg:grid-cols-4 md:grid-rows-6 md:grid-cols-2 grid-rows-6 md:gap-4 gap-2">
            {currentItems?.map((item, index) => (
              <div key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id={`checkbox-${item?.id}`}
                  checked={selectedCheckboxes?.includes(item?.id)}
                  onChange={() => handleCheckboxChange(item?.id)}
                />
                <label
                  className="text-[#272727] font-normal md:text-[15px] text-[10px]"
                  htmlFor={`checkbox-${item?.id}`}
                >
                  {item?.name}
                </label>
              </div>
            ))}
          </div>

          {/* <div className="grid place-items-center grid-cols-4 w-full place-content-between mt-5"> */}

          <div className="flex items-center md:flex-row flex-col justify-between mt-5 gap-5">
            <div className="flex justify-between md:w-[60%] w-[100%] items-center">
              <div>
                <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  Showing {startIndex + 1} to{" "}
                  {Math.min(
                    startIndex + currentItems?.length,
                    cases?.data?.total
                  )}{" "}
                  of {cases?.data?.total} Entries
                </p>

                {/* <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  Showing {startIndex + 1} to{" "}
                  {startIndex + currentItems?.length} of {cases?.data?.total}{" "}
                  Entries
                </p> */}
              </div>

              <div className="flex gap-2 items-center">
                <p className="font-normal lg:text-[16px] md:text-[13px] text-[9px] text-black">
                  No. of Questions
                </p>
                <div className="border-[1px] border-black md:px-3 md:py-1 px-1 py-1">
                  <input
                    type="text"
                    maxLength={2}
                    style={{
                      width: 20,
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                    }}
                    onChange={(e) => {
                      // const value = e.target.value;
                      // // Limit to 2 digits
                      // if (/^\d{0,2}$/.test(value)) {
                      //   setLimitText(value);
                      // }

                      const value = e.target.value;

                      // Limit to 2 digits and ensure value is between 0 and 80
                      if (
                        /^\d{0,2}$/.test(value) &&
                        (value === "" || parseInt(value, 10) <= 80)
                      ) {
                        setLimitText(value);
                      }
                    }}
                    value={limitText}
                  />
                  {/* <p className="text-[10px] md:text-[10px] text-[8px] text-black">
                    40
                  </p> */}
                </div>
                <p className="text-xs text-[#9F9F9F] font-normal">
                  Max allowed per block
                </p>
                <div className="border-[1px] bg-[#E2E2E2] px-[2px]">
                  <p className="text-[8px] text-[#646464]">80</p>
                </div>
              </div>
            </div>

            <div className="flex bg-white border-[#E2E2E2] border-[1px] rounded-md py-[2px] px-[2px] shadow-md">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="md:px-3 px-1 py-1 text-[#2172DC] lg:text-[16px] md:text-[13px] text-[10px]"
              >
                Previous
              </button>

              <div className="flex">
                {visiblePageNumbers?.map((pageNumber) => (
                  <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    className={`px-3 py-1 md:text-[14px] text-[10px] ${
                      currentPage === pageNumber
                        ? "bg-[#060531] text-white rounded"
                        : "text-[#000000]"
                    }`}
                  >
                    {pageNumber}
                  </button>
                ))}
              </div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="md:px-3 md:py-1 px-2 py-2 text-[#2172DC] lg:text-[16px] md:text-[13px] text-[10px]"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
