import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoggedIn: false,
  token: "",
  email: "",
  casesForTest: [],
  questionLimit: 10,
};

const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    UpdateReducer: (state, action) => {
      state.user = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCasesForTest: (state, action) => {
      state.casesForTest = action.payload;
    },
    setQuestionLimitForTest: (state, action) => {
      state.questionLimit = action.payload;
    },
    LoginUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      state.token = action.payload.token;
      localStorage.setItem("token", action.payload?.token);
    },

    GetUser: (state, action) => {
      let token = localStorage.getItem("token");

      if (token) {
        state.token = token;
        state.isLoggedIn = true;
      }
    },

    LogoutUser: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.token = "";
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(AuthActions.Login.fulfilled, (state, action) => {
    //   state.user = action.payload;
    //   state.isLoggedIn = true;
    //   state.token = action.payload.token;
    // });
    // builder.addCase(AuthActions.Register.fulfilled, (state, action) => {
    //   state.user = action.payload;
    //   state.isLoggedIn = true;
    //   state.token = action.payload.token;
    // });
    // builder.addCase(AuthActions.EditProfile.fulfilled, (state, action) => {
    //   localStorage.setItem(
    //     "@user",
    //     JSON.stringify({ ...action.payload, token: "asdesadas" })
    //   );
    //   state.user = action.payload;
    // });
  },
});

export const {
  UpdateReducer,
  setCasesForTest,
  LoginUser,
  LogoutUser,
  GetUser,
  setEmail,
  setUserData,
  setQuestionLimitForTest,
} = AuthSlice.actions;
export default AuthSlice.reducer;
