"use client";

import React from "react";

import { useNavigate } from "react-router-dom";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import TextFields from "../../Components/TextField";
import CustomButton from "../../Components/CustomButton";

const ChangePassword = () => {
  const navigate = useNavigate();

  const handleClicked = () => navigate("/");
  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        <CardHeader header="Change Password" onClick={() => navigate(-1)} />

        <div className="p-8 bg-[#FFFFFF] shadow-md rounded-b-lg">
          <div className="mt-10 space-y-10">
            <TextFields
              placeholder="Current Password"
              type="password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
            />

            <TextFields
              placeholder="New Password"
              type="password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
            />

            <TextFields
              placeholder="Confirm Password"
              type="password"
              icon={"/assets/Group 81768.svg"}
              width="100%"
              height="50px"
            />
          </div>

          <div className="flex justify-center" style={{ marginTop:"2rem", marginBottom:"2rem" }}>
            <CustomButton
              text="Update"
              onClick={handleClicked}
              width="160px"
              height="60px"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ChangePassword;
