import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button";
import { IMAGES } from "../../../utils/Images";
import { post } from "../../../api/axios.tsx";
import { Toast } from "../../../Components/toast.jsx";

export default function ConfirmFinishModal({
  showConfirmFinishModal,
  setShowConfirmFinishModal,
  setShowFinishModal,
  id,
}) {
  const navigate = useNavigate();
  const handleNavigate = async () => {
    try {
      const response = await post({
        url: "/finish-test",
        data: { user_test_id: id },
      });

      if (response?.data != null) {
        Toast({ message: response?.messages?.[0] });
        console.log();
        setShowConfirmFinishModal(false);
        navigate("/");
      }
    } catch (error) {
      Toast({ message: error?.message, isError: true });
      console.log(error?.message);
    }
  };

  return (
    <>
      {showConfirmFinishModal && (
        <>
          <div className="fixed inset-0  w-full z-50 h-full flex items-center justify-center">
            <div className="bg-white shadow-md border-[1px] border-[#8C8C8C] max-w-[800px] w-[90%] rounded-md ">
              <div
                className={`bg-[#4D4D4D] text-white flex rounded-t-[4px] p-3 items-center cursor-pointer`}
              >
                <p className="text-white font-normal text-[14px]">
                  Confirm Finish Section
                </p>
              </div>

              <div className="md:w-[70%] w-[90%] mx-auto grid place-items-center text-center p-4 gap-5">
                <img
                  src={IMAGES.DANGER}
                  className="md:w-[135px] md:h-[119px] w-[80px] h-[90px] object-contain"
                />

                <p className="text-[#272727] lg:text-[24px] md:text-[18px] text-[14px]">
                  If you select <strong>Finish Section</strong> . your answer
                  will be submitted and you will not be able to return to this
                  <strong> section.</strong>
                </p>

                <div className="grid grid-cols-2 md:gap-7 gap-4 w-full md:mb-10 mb-5">
                  <Button
                    onPress={() => {
                      setShowFinishModal(true);
                      setShowConfirmFinishModal(false);
                    }}
                    divstyle={`w-full rounded-lg text-white bg-[#060531] font-regular py-2 lg:text-[24px] md:text-[18px] sm:text-[14px] text-[11px]`}
                    btnname="Cancel"
                  />
                  <Button
                    onPress={handleNavigate}
                    divstyle={`w-full rounded-lg text-white bg-[#060531] font-regular py-2 lg:text-[24px] md:text-[18px] sm:text-[14px] text-[11px]`}
                    btnname="Finish Section"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#000000]"></div>
        </>
      )}
    </>
  );
}
