const CustomButton = ({ text, color, onClick, extraClass = '' }) => (
  <button
    onClick={onClick}
    className={`w-full py-2 sm:py-3 rounded-lg text-sm font-medium ${color} ${extraClass}`}
  >
    {text}
  </button>
);

  
  export default CustomButton;