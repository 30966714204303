import React from "react";

import { useNavigate } from "react-router-dom";

import Container from "../../Components/Container";
import CardHeader from "../../Components/CardHeader";
import SettingCard from "../../Components/SettingCard";

function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <Container>
      <div
        className={`w-full h-full p-3 sm:p-6 md:px-12 md:p-8 bg-[#FFFFFF]`}
        // style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}
      >
        {" "}
        <CardHeader header="Privacy Policy" onClick={() => navigate(-1)} />
        <SettingCard
          header=""
          paragraph={`
            Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Except eur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
            Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Except eur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.<br />
            Lorem ipsum dolor sit amet, consectetur adipi scing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Except eur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          `}
        />
      </div>
    </Container>
  );
}

export default PrivacyPolicy;
