import React from "react";
import CustomButton from "./Btn";

const PlanCard = ({
  title,
  price,
  features,
  buttonText,
  buttonColor,
  onButtonClick,
  titleColor,
  circleColor,
}) => (
  <div className="bg-white shadow-md rounded-lg p-6 w-full md:w-1/2 h-[60vh] flex flex-col sm:overflow-auto ">
    <div className="flex items-center gap-2 mb-4">
      <div className={`w-5 h-5 rounded-full ${circleColor}`}></div>
      <h2 className={`text-lg font-bold ${titleColor}`}>{title}</h2>
    </div>

    <div className="flex items-baseline mb-4">
      <p className="text-3xl font-bold text-gray-800">{price}</p>
      <span className="text-gray-500 text-sm ml-2">/month</span>
    </div>

    <ul className="list-disc list-inside mt-6 space-y-1 text-[14px] text-gray-500 flex-grow overflow-y-auto">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>

    <CustomButton
      text={buttonText}
      color={buttonColor}
      onClick={onButtonClick}
      extraClass="mt-6"
    />
  </div>
);

export default PlanCard;
