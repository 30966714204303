// import React, { useState, useEffect } from "react";
// import { FilterMatchMode } from "primereact/api";
// import { DataTable } from "primereact/datatable";
// import ComponentHeader from "./Header";
// import { Column } from "primereact/column";
// import { NormalValuesTableData } from "./data";

// export default function NormalValuesTable({ showLeftNormalTable }) {
//   const [customers, setCustomers] = useState(null);
//   const [filters, setFilters] = useState({
//     test: { value: null, matchMode: FilterMatchMode.CONTAINS },
//     observed: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
//   });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     setCustomers(getCustomers(NormalValuesTableData));
//     setLoading(false);
//   }, []);

//   const getCustomers = (data) => {
//     return [...(data || [])].map((d) => {
//       d.date = new Date(d.date);
//       return d;
//     });
//   };

//   const countryBodyTemplate = (rowData) => {
//     return <p className="text-[15px]">{rowData}</p>;
//   };

//   const score = (value) => {
//     return (
//       <div
//         className={`${
//           value ? "bg-[#E8E8E8] rounded-full  size-10" : ""
//         }  flex items-center justify-center text-[14px]`}
//       >
//         {value}
//       </div>
//     );
//   };

//   return (
//     <div className="h-[100%]">
//       <div
//         className={`${
//           !showLeftNormalTable && "hidden"
//         }   h-full max-w-screen-md md:w-full `}
//       >
//         {showLeftNormalTable && (
//           <div className="flex-1 border-[#4D4D4D] border-2  rounded-md h-[100%]">
//             {/*Header   */}
//             <ComponentHeader />
//             {/* Header */}

//             {/* Component */}

//             <DataTable
//               showGridlines
//               value={customers}
//               dataKey="id"
//               filters={filters}
//               filterDisplay="menu"
//               loading={loading}
//               globalFilterFields={["Test Parameter", "Observed Value"]}
//               emptyMessage="No data found."
//             >
//               <Column
//                 field="test"
//                 header="Test Parameter"
//                 body={(e) => score(e.score)}
//               />
//               <Column
//                 field="observed"
//                 header="Observed Value"
//                 style={{ minWidth: "200px" }}
//                 body={(e) => countryBodyTemplate(e.name)}
//               />
//             </DataTable>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import ComponentHeader from "./Header";
import { Column } from "primereact/column";
import { NormalValuesTableData } from "./data";

import styles from "./normalTable.module.css";

export default function NormalValuesTable({ showLeftNormalTable, values }) {
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState({
    test: { value: null, matchMode: FilterMatchMode.CONTAINS },
    value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);

  console.log(values);

  useEffect(() => {
    setCustomers(getCustomers(values));
    setLoading(false);
  }, []);

  const getCustomers = (data) => {
    return [...(values || [])];
  };

  return (
    <div className="h-[100%] w-[100%]">
      <div
        className={`${
          !showLeftNormalTable && "hidden"
        }   h-full max-w-screen-md md:w-full `}
      >
        {showLeftNormalTable && (
          <div className="flex-1  border-[#4D4D4D] border-2 rounded-md  w-full">
            {/*Header   */}
            <ComponentHeader />
            {/* Header */}

            {/* Component */}
            <div className="flex justify-center scroll-container h-[615px] w-full ">
              <DataTable
                showGridlines
                value={customers}
                dataKey="id"
                filters={filters}
                filterDisplay="menu"
                loading={loading}
                globalFilterFields={["test_parameter", "observed_value"]}
                emptyMessage="No data found."
                className="rounded-[10px] w-full mt-[4px] md:ml-[40px] ml-[10px] mr-[5px] border-2 border-[#767D83] h-full  "
              >
                <Column
                  field="test_parameter"
                  // header="Test Parameter"
                  header= {
                    <span className={styles.headerClass}>
                      Test Parameter
                    </span>
                  }
                  className="md:text-[16px] text-[10px] text-center bg-[#D1E3EB] "
                  headerClassName="bg-[#ACCBDA] flex items-center h-full justify-center md:text-[16px] rounded-ss-lg border-b-[1px] border-[#767D83] sm:text-[13px] text-[10px]"
                />
                <Column
                  field="observed_value"
                  // header="Observed Value"
                  header= {
                    <span className={styles.headerClass}>
                      Observed Value
                    </span>
                  }
                  className="md:text-[16px] text-[10px]  bg-[#D1E3EB] "
                  headerClassName="bg-[#ACCBDA] md:text-[15px] sm:text-[13px] text-[9px] rounded-se-lg border-b-[1px] border-[#767D83]"
                />
              </DataTable>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
