import React from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"; // Import icons

const TextFields = ({
  placeholder,
  type = "text",
  icon,
  width = "100%",
  height = "56px",
  multiline = false,
  rows = 1,
  value,
  name,
  onChange,
  disabled = false, 
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="relative" style={{ width }}>
      {icon && (
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
          <img src={icon} alt="input-icon" width={20} loading="lazy" />
        </div>
      )}
      {multiline ? (
        <textarea
          placeholder={placeholder}
          rows={rows}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled} 
          className={`w-full p-3 ${icon ? "pl-10" : "pl-2"} 
            ${disabled ? "bg-gray-300" : "bg-white"} 
            border border-gray-300 rounded-lg shadow-sm focus:outline-none 
            focus:ring-2 focus:ring-[#060531]`}
          style={{ height: "auto" }}
        />
      ) : (
        <input
          placeholder={placeholder}
          type={type === "password" && !showPassword ? "password" : "text"}
          value={value}
          name={name}
          onChange={onChange}
          disabled={disabled} 
          className={`w-full p-3 ${icon ? "pl-10" : "pl-2"} 
            ${disabled ? "bg-[#E5E5E5]" : "bg-white"} 
            border border-[#E5E5E5] rounded-lg shadow-sm focus:outline-none 
            focus:ring-2 focus:ring-[#060531]`}
          style={{ height }}
        />
      )}
      {type === "password" && !disabled && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-1/2 transform -translate-y-1/2"
        >
          {showPassword ? (
            <AiFillEye size={20} />
          ) : (
            <AiFillEyeInvisible size={20} />
          )}
        </button>
      )}
    </div>
  );
};

export default TextFields;
