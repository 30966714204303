import React, { useState } from "react";
import Login from "./Components/Login";
import Register from "./Components/Register";
import { IMAGES } from "../../utils/Images";
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../Components/AuthHeader";

const Index = () => {
  const [show, setShow] = useState(0);

  const navigate = useNavigate();

  return (
    <div className="w-full min-h-screen h-auto flex ">
      <AuthHeader />
      <div className="flex flex-col items-center justify-center  bg-[#060531] md:w-[50%] w-full ml-auto lg:w-6/12 xl:w-6/12">
        <img
          src={IMAGES.LOGO}
          className="block md:hidden mx-auto md:h-[120px] md:w-[180px]  h-[90px] w-[90px] object-contain my-10 "
          alt="Logo"
        />
        <div className=" md:max-w-screen-sm max-w-screen-[90%]">
          <div className="flex items-center justify-center max-w-[75%] mx-auto md:mt-4 py-[6px] px-[6px] rounded-full bg-white gap-10 mb-8">
            <button
              onClick={() => setShow(0)}
              className={`w-32 py-2 rounded-full transition-transform duration-500 ${
                show === 0
                  ? "bg-[#060531] text-white"
                  : "bg-transparent text-[#060531] text-center"
              }`}
            >
              Sign in
            </button>
            <button
              onClick={() => setShow(1)}
              className={`w-32 py-2 rounded-full transition-transform duration-500 ${
                show === 1
                  ? "bg-[#060531] text-white"
                  : "bg-transparent text-[#060531] text-center"
              }`}
            >
              Sign Up
            </button>
          </div>

          {/*  */}

          <div>
            {show === 0 ? (
              <Login navigate={navigate} />
            ) : (
              <Register navigate={navigate} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
