import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IMAGES } from "../../utils/Images";
import { CustomIcon } from "../../Components/Icons";
import { get } from "../../api/axios.tsx";

// Circular progress bar component
const ProgressCircle = ({ percentage, color, label }) => (
  <div style={{ width: 150, height: 150 }}>
    <CircularProgressbarWithChildren
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: color,
        trailColor: "#E8E8E8",
      })}
      value={percentage}
      strokeWidth={5}
    >
      <div
        style={{
          fontSize: 19,
          marginTop: -5,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p className="text-[#404558] text-3xl">{`${percentage}%`}</p>
        <p className="text-[#C5B9B4] text-lg">{label}</p>
      </div>
    </CircularProgressbarWithChildren>
  </div>
);

// List item component
const ScoreListItem = ({ text, value, color }) => (
  <div className="flex justify-between items-center my-2 border-b-2 border-[#E8E8E8] py-1">
    {color ? (
      <div className="flex items-center gap-3">
        <div
          style={{
            backgroundColor: color,
            width: "12px",
            height: "12px",
            borderRadius: "50%",
          }}
        />
        <li className="text-[#6B6E69] text-start">{text}</li>
      </div>
    ) : (
      <li className="text-[#6B6E69] text-start">{text}</li>
    )}
    <div className="bg-[#E8E8E8] rounded-full py-1 px-3">
      <li className="text-[#6B6E69]">{value?.toString()}</li>
    </div>
  </div>
);

// Reusable section component
const Section = ({ title, items, className }) => (
  <div className={`p-2 w-[100%] ${className}`}>
    <h2 className="text-lg font-semibold">{title}</h2>
    <ul className="mt-2 text-sm">
      {items.map((item, index) => (
        <ScoreListItem
          key={index}
          text={item.text}
          value={item.value}
          color={item.color}
        />
      ))}
    </ul>
  </div>
);

const Performance = () => {
  const [data, setData] = useState(null);

  async function getData() {
    try {
      const response = await get({
        url: "/statistics",
      });
      console.log(response);

      if (response?.data != null) {
        setData(response?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  const percentageOne = data?.correct_percentage;

  const scoreItemsOne = [
    { text: "Total Correct", value: data?.correct },
    { text: "Total Incorrect", value: data?.incorrect },
    { text: "Total Omitted", value: data?.omitted },
  ];

  const scoreItemsTwo = [
    { text: "Correct to Incorrect", value: data?.correct_to_incorrect },
    { text: "Incorrect to Correct", value: data?.incorrect_to_correct },
    { text: "Incorrect to Incorrect", value: data?.incorrect_to_incorrect },
  ];

  const scoreItemsThree = [
    { text: "Used Questions", value: data?.correct },
    { text: "Unused Questions", value: data?.correct },
    { text: "Total Questions", value: data?.correct },
  ];

  const scoreItemsFour = [
    { text: "Tests Created", value: data?.created },
    { text: "Test Completed", value: data?.completed },
    { text: "Suspended Tests", value: data?.suspended },
  ];

  const scoreItemsFive = [
    { color: "#69C869", text: "Your Score", value: data?.score },
    {
      color: "#2196F3",
      text: "Medium Score",
      value: data?.median_score,
    },
    { text: "Your Average Time Spent", value: data?.avg_time_spent },
  ];

  return (
    <Container>
      <div className="px-5 py-3">
        <div className="py-2 w-full flex items-center justify-between border-b-2 border-[#E8E8E8]">
          <h1 className="text-xl font-normal">Statistics</h1>
          <div onClick={()=>window.print()} className="flex justify-center items-center gap-1 cursor-pointer">
            <CustomIcon
              name="nimbus:printer"
              className="!text-[#69B9F7]"
              fontSize={18}
            />
            <h6 className="text-base font-normal text-[#69B9F7]"> Print</h6>
          </div>
        </div>

        <div className=" w-full grid grid-cols-1 lg:grid-cols-12 gap-10 justify-between mt-8">
          <div className=" lg:col-span-7 flex flex-col gap-5 ">
            <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-items-center flex-wrap">
              <ProgressCircle
                percentage={percentageOne === undefined ? "0" :percentageOne }
                color="#69C869"
                label="Correct"
              />
              <Section
                className={"col-span-2"}
                title="Your Score"
                items={scoreItemsOne}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 items-center justify-items-center">
              {/* <ProgressCircle
                percentage={percentageTwo}
                color="#1E88E5"
                label="Used"
              /> */}
              {/* <Section
                className={"col-span-2"}
                title="QBank Usage"
                items={scoreItemsThree}
              /> */}
            </div>

            {/* <div className="grid grid-cols-1  ">
              <img
                src={IMAGES.PERFOMANCE}
                draggable={false}
                alt="per"
                height={200}
                width={600}
                className="mx-auto"
              />
            </div> */}
          </div>

          <div className="lg:col-span-5 grid grid-cols-1 lg:grid-cols-1 gap-5">
            <Section title="Answer Changes" items={scoreItemsTwo} />
            <Section title="Test Count" items={scoreItemsFour} />
            <Section title="Percentile Rank" items={scoreItemsFive} />

            {/* <div className="col-span-2 w-[50%] items-end ">
              <Section title="Percentile Rank" items={scoreItemsFive} />
            </div> */}
          </div>
          {/* <div className="bg-red-400 h-60 grid grid-cols-12 col-span-12">
            <div className="bg-green-600 col-span-7">

            </div>

            <div className="col-span-5">

          <Section title="Percentile Rank" items={scoreItemsFive} />
          </div>


          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default Performance;
