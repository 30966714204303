import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CustomerService } from "./data";
import { CustomIcon } from "../../Components/Icons";
import { Dropdown } from "primereact/dropdown";
import { IMAGES } from "../../utils/Images";
import { get } from "../../api/axios.tsx";
import { Toast } from "../../Components/toast.jsx";

export default function BasicFilterDemo() {
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    pool: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    subject: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    systems: { value: null, matchMode: FilterMatchMode.IN },
    qs: { value: null, matchMode: FilterMatchMode.EQUALS },
    action: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [data, setData] = useState(null);

  async function getData() {
    try {
      const response = await get({
        url: "/previous-tests",
      });
      console.log(response);

      if (response?.data != null) {
        setData(response?.data);
      }
    } catch (error) {
      Toast({ message: error?.message, isError: true });
      console.log(error?.message);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCustomers(getCustomers(data?.data ?? []));
    setLoading(false);
  }, [data]);

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);
      return d;
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const countryBodyTemplate = (rowData, field) => {
    if (field === "date") {
      return (
        <p className="text-[15px]">
          {rowData.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </p>
      );
    }
    return <p className="text-[15px]">{rowData}</p>;
  };

  const actionIcon = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        {rowData.score ? (
          <div className="flex gap-2">
            <img
              src={IMAGES.VIDEO}
              className="w-[20px] h-[20px] object-contain"
            />
            <img
              src={IMAGES.CHECKED_CHART}
              className="w-[20px] h-[20px] object-contain"
            />
            <img
              src={IMAGES.GRAPH_CHART}
              className="w-[20px] h-[20px] object-contain"
            />
          </div>
        ) : (
          <img
            src={IMAGES.VIDEO}
            className="w-[20px] h-[20px] object-contain"
          />
        )}
      </div>
    );
  };

  const score = (value) => {
    return (
      <div
        className={`${
          value ? "bg-[#E8E8E8] rounded-full  size-10" : ""
        }  flex items-center justify-center text-[14px]`}
      >
        {value}
      </div>
    );
  };
  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 120, value: 120 },
      ];
      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="card">
      <div className="flex justify-between items-center  md:px-8 px-4">
        <div className="flex items-center">
          {/* <p className="text-[#b3b3b6] font-medium text-sm">Show:</p> */}
          {/* <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            class="text-[#b3b3b6] focus:outline-none font-medium border-b border-[#b3b3b6]  text-sm ml-2 pt-1 text-center inline-flex items-center  "
            type="button"
          >
            Columns
            <svg
              class="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button> */}

          <div
            id="dropdown"
            class="z-10 hidden bg-white   rounded-lg shadow w-44 "
          >
            <ul class="py-2 text-sm text-gray-700 ">
              <li>
                <a href="#" className="block px-4 py-2">
                  Dashboard
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-b-[1px] ml-auto my-8 mx-6  border-[#b3b3b6] flex justify-between items-center w-[30%] ">
          <input
            type
            placeholder="Search"
            className=" focus:outline-none w-[90%] py-1 px-2"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
          />
          <CustomIcon name={"ic:round-search"} className={"!text-black"} />
        </div>
      </div>

      {/* </div> */}
      <DataTable
        showGridlines
        value={customers}
        paginator
        paginatorTemplate={template2}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorClassName="focus:outline-none outline-none justify-end"
        rows={10}
        dataKey="id"
        filters={filters}
        filterDisplay="menu"
        loading={loading}
        globalFilterFields={[
          "test_name",
          "date",
          "score",
          "subject",
          "questions",
        ]}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="No data found."
      >
        <Column
          field="score"
          header="SCORE"
          headerClassName="flex justify-center item-center"
          className="flex justify-center  item-center"
          body={(e) => score(e.score)}
        />
        <Column
          field="name"
          header="NAME"
          className="border-b"
          // style={{ minWidth: "20px" }}
          body={(e) => countryBodyTemplate(e.test_name)}
        />
        <Column
          field="date"
          header="DATE"
          className="border-b"
          body={(e) => countryBodyTemplate(e.date, "date")}
        />
        {/* <Column
          field="mode"
          header="MODE"
          className="border-b"
          body={(e) => countryBodyTemplate(e.mode)}
        />
        <Column
          field="pool"
          header="Q.POOL"
          className="border-b"
          body={(e) => countryBodyTemplate(e.pool)}
        /> */}
        <Column
          field="subject"
          header="SUBJECTS"
          className="border-b"
          body={(e) => countryBodyTemplate(e.cases?.[0] ?? "")}
        />

        {/* <Column
          field="system"
          header="SYSTEM"
          className="border-b"
          body={(e) => countryBodyTemplate(e.systems)}
        /> */}

        <Column
          field="qs"
          header="#QS"
          className="border-b"
          body={(e) => countryBodyTemplate(e.questions)}
        />

        <Column
          field="action"
          className="border-b"
          header="ACTION"
          body={actionIcon}
        />

        {/* <Column header="ACTION" body={representativeBodyTemplate} /> */}
      </DataTable>
    </div>
  );
}
