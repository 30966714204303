export const NormalValuesTableData = [
  {
    id: "1",
    test: "Hemoglobin",
    value: "12.8g/dL",
  },
  {
    id: "2",
    test: "White blood cells",
    value: "8.2x10 cells/mm3",
  },
  {
    id: "3",
    test: "Hematocrit",
    value: "38.6%",
  },
  {
    id: "4",
    test: "Mean cell volume",
    value: "77.2fL",
  },
  {
    id: "5",
    test: "Mean cell hemoglobin",
    value: "25.6pg/cell",
  },

  {
    id: "6",
    test: "Mean cell hemoglobin concentration",
    value: "33.2g/dL",
  },
  {
    id: "7",
    test: "Fasting Blood sugar",
    value: "78mg/dL",
  },
  {
    id: "8",
    test: "Blood urea",
    value: "42mg/dL.",
  },
  {
    id: "9",
    test: "Serum creatinine",
    value: "0.9mg/dL",
  },

  {
    id: "10",
    test: "Blood urea nitrogen",
    value: "10mg/dL",
  },
  {
    id: "11",
    test: "Aspartate aminotransferase",
    value: "46U/L",
  },
  {
    id: "12",
    test: "Alanine aminotransferase",
    value: "34U/L",
  },
  {
    id: "13",
    test: "Alkaline phosphates",
    value: "207U/L",
  },
  {
    id: "14",
    test: "Bilirubin Total",
    value: "0.5mg/dL",
  },

  {
    id: "15",
    test: "Bilirubin Direct",
    value: "0.3mg/dL",
  },

  {
    id: "16",
    test: "Bilirubin Indirect",
    value: "0.2mg/dL",
  },
  {
    id: "17",
    test: "Urine sugar",
    value: "Nil",
  },

  {
    id: "18",
    test: "Urine albumin",
    value: "Nil",
  },
  {
    id: "19",
    test: "Ketone bodies",
    value: "Nil",
  },

  {
    id: "20",
    test: "Anti-Ro/SSA autoantibody",
    value: "89%",
  },
  {
    id: "21",
    test: "Anti-La/SSB",
    value: "Absent",
  },

  {
    id: "22",
    test: "Anti-dsDNA",
    value: "12.0IU/ml",
  },
];
