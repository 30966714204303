import { toast } from 'react-hot-toast';

export const Toast = ({ message, isError = false, position = "top-right", autoClose = 2000 }) => {
    return toast(message, {
        type: isError ? "error" : "success",
        position: position,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: false,
        autoClose
    });
};