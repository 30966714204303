import React from "react";
import Container from "../../Components/Container";
import BasicFilterDemo from "./table";

const Previous = () => {
  // const data = [
  //   {
  //     score: "",
  //     name: "8",
  //     date: "Jan 08, 2024",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "Medicine",
  //     systems: "All",
  //     questions: 80,
  //   },
  //   {
  //     score: "",
  //     name: "7",
  //     date: "Mar 28, 2024",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "Psychiatry",
  //     systems: "Psychiatric/Behavioral & Substance...",
  //     questions: 68,
  //   },
  //   {
  //     score: "",
  //     name: "6",
  //     date: "Jan 16, 2024",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "Surgery",
  //     systems: "All",
  //     questions: 21,
  //   },
  //   {
  //     score: "",
  //     name: "5",
  //     date: "Oct 24, 2023",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "Obstetrics & Gynecology",
  //     systems: "All",
  //     questions: 80,
  //   },
  //   {
  //     score: "",
  //     name: "4",
  //     date: "Sep 03, 2023",
  //     mode: "Tailored, Untimed",
  //     qPool: "Multiple",
  //     subjects: "Multiple",
  //     systems: "Allergy & Immunology",
  //     questions: 9,
  //   },
  //   {
  //     score: "66%",
  //     name: "3",
  //     date: "Sep 03, 2023",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "All",
  //     systems: "Allergy & Immunology",
  //     questions: 5,
  //   },
  //   {
  //     score: "",
  //     name: "2",
  //     date: "Aug 23, 2023",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "All",
  //     systems: "Gastrointestinal & Nutrition",
  //     questions: 59,
  //   },
  //   {
  //     score: "",
  //     name: "1",
  //     date: "Aug 13, 2023",
  //     mode: "Tailored, Untimed",
  //     qPool: "Unused",
  //     subjects: "Medicine",
  //     systems: "All",
  //     questions: 80,
  //   },
  // ];

  return (
    <Container mainStyle="">
      <BasicFilterDemo />
    </Container>
  );
};

export default Previous;
