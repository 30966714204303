import { useState } from "react";
import Button from "../../../Components/Button";
import Inputfield from "../../../Components/Inputfield";
import { IMAGES } from "../../../utils/Images";

export default function MessageModal({
  showMessageModal,
  setShowMessageModal,
  enteredText,
  setEnteredText,
}) {
  const clear = () => {
    setEnteredText("");
  };

  return (
    <>
      {showMessageModal && (
        <>
          <div className="fixed inset-0  w-full z-50 h-full flex items-center justify-center">
            <div className="bg-white shadow-md  border-[1px] border-[#8C8C8C] max-w-[800px] w-full rounded-md ">
              <div
                className={`bg-[#4D4D4D] text-white flex rounded-t-[4px] p-3 items-center  `}
              >
                <p className="text-white font-normal text-[14px]">
                  Leave a comment
                </p>
              </div>

              <div className="w-full p-4  ">
                <textarea
                  placeholder=""
                  rows={10}
                  maxLength={500}
                  onChange={(text) => {
                    setEnteredText(text.target.value);
                  }}
                  value={enteredText}
                  className="w-full focus:outline-none border-2 border-[#272727] rounded-md p-2"
                ></textarea>

                <div className=" my-1">
                  <p className="text-[14px] text-[#272727] font-normal">
                    {500 - enteredText?.length} characters remaining out of 500
                  </p>
                  <div className="flex justify-between">
                    <div>
                      <Button
                        onPress={() => clear()}
                        divstyle="px-8 rounded-lg text-white bg-[#060531] font-regular  lg:py-[3px] py-[2px]  text-[18px]"
                        btnname="Remove Comment"
                      />
                    </div>
                    <div className="flex gap-4">
                      <Button
                        onPress={() => setShowMessageModal(false)}
                        divstyle="px-8   rounded-lg text-white bg-[#060531] font-regular  lg:py-[3px] py-[2px]  text-[18px]"
                        btnname="Save"
                      />
                      <Button
                        onPress={() => setShowMessageModal(false)}
                        divstyle="px-6 rounded-lg text-[#060531] bg-white border-2 border-[#060531]  font-regular  lg:py-[3px] py-[2px] text-[18px]"
                        btnname="Cancel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-[#000000]"></div>
        </>
      )}
    </>
  );
}
