import React from "react";

const Modal = ({
  showModal,
  customStyle = " w-full",
  childrenOne,
  childrenTwo,
  childrenThree,
  childrenFour,
}) => {
  return (
    <>
      {showModal && (
        <>
          <div className=" inset-0 z-50 h-full flex items-center justify-center ">
            <div
              className={`bg-white shadow-md  border-[1px] border-[#8C8C8C]  max-h-[900px] max-w-[650px] ${customStyle} rounded-md`}
            >
              <div
                className={`${
                  childrenOne || childrenTwo || childrenThree
                    ? "bg-[#4D4D4D] text-white"
                    : "bg-white text-[#4D4D4D]"
                }   flex rounded-t-[4px] justify-between p-2 items-center`}
              >
                <div>{childrenOne}</div>
                <div>{childrenTwo}</div>
                <div>{childrenThree}</div>
              </div>

              <div
                className={`items-center  flex justify-center ${
                  childrenOne || childrenTwo || childrenThree ? "py-0" : "py-8 "
                } `}
              >
                {childrenFour}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Modal;
