import React from "react";

const Button = ({ divstyle, btnStyle, btnname, type, onPress, disabled }) => {
  return (
    <>
      <button
        className={divstyle}
        type={type}
        onClick={onPress}
        disabled={disabled}
      >
        <p className={btnStyle}>{btnname}</p>
      </button>
    </>
  );
};

export default Button;
