import React, { useEffect, useState } from "react";
import StartTest from "./StartTest";
import { useLocation } from "react-router-dom";

const Test = () => {
  const location = useLocation();

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (location.state?.showModal) {
      setShowModal(true);
    }
  }, [location.state]);

  return (
    <div className="flex justify-center items-center h-full ">
      {showModal && (
        <StartTest showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default Test;
